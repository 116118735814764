import * as React from 'react';
import {
  ReferenceType,
  offset,
  shift,
  flip,
  useFloating,
  autoUpdate,
  useInteractions,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useClick,
  arrow,
  FloatingArrow,
  useTransitionStyles,
  Placement,
} from '@floating-ui/react';
import cn from 'classnames';

export interface RenderOpenerProps extends Record<string, unknown> {
  ref: (node: ReferenceType | null) => void;
}

export interface TooltipProps {
  content?: React.ReactNode;
  renderOpener: (props: RenderOpenerProps) => React.ReactNode;
  placement?: Placement;
  withArrow?: boolean;
  className?: string;
  bodyOffset?: number;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onDisplay?: () => void;
  onDismiss?: () => void;
  action?: 'hover' | 'click';
  forcePlacement?: boolean;
  noWrap?: boolean;
}

export const Tooltip = ({
  content,
  renderOpener,
  placement,
  className,
  withArrow,
  bodyOffset = 5,
  onMouseEnter,
  onMouseLeave,
  onDisplay,
  onDismiss,
  action = 'hover',
  forcePlacement,
  noWrap,
}: TooltipProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const arrowRef = React.useRef(null);
  const parentDivRef = React.useRef(null);
  const [arrowColor, setArrowColor] = React.useState('');

  const {
    refs: { setReference, setFloating },
    floatingStyles,
    context,
  } = useFloating({
    open: isOpen,
    onOpenChange(nextOpen, event, reason) {
      setIsOpen(nextOpen);
      if ((reason === 'click' || reason === 'focus') && nextOpen) {
        onDisplay?.();
      }
      if (reason === 'escape-key' || reason === 'outside-press') {
        onDismiss?.();
      }
    },
    placement,
    middleware: [
      offset(bodyOffset),
      !forcePlacement && flip(),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, { move: false, enabled: action === 'hover' });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });
  const click = useClick(context, { enabled: action === 'click' });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role, click]);

  const { styles: transitionStyles } = useTransitionStyles(context, {
    initial: {
      opacity: 0,
      transform: 'scale(0.8)',
    },
  });

  React.useEffect(() => {
    if (parentDivRef.current) {
      const computedStyle = getComputedStyle(parentDivRef.current);
      setArrowColor(computedStyle.backgroundColor);
    }
  }, [isOpen, content]);

  return (
    <>
      {renderOpener({ ref: setReference, onMouseEnter, ...getReferenceProps(), onMouseLeave })}
      {isOpen && content && (
        <div ref={setFloating} style={{ ...floatingStyles, zIndex: 100 }} {...getFloatingProps()}>
          <div
            ref={parentDivRef}
            style={transitionStyles}
            className={cn(
              'capitalize rounded bg-blue text-white py-2 px-3 text-sm',
              noWrap && 'whitespace-nowrap',
              className,
            )}
          >
            {withArrow && (
              <FloatingArrow fill={arrowColor} tipRadius={2} height={8} ref={arrowRef} context={context} />
            )}
            {content}
          </div>
        </div>
      )}
    </>
  );
};
