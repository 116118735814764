import * as React from 'react';
import cn from 'classnames';
import { Tooltip } from 'styleguide/components/Tooltip/Tooltip';
import { IconType } from 'react-icons';
import { IconBaseProps } from 'react-icons/lib/esm/iconBase';

interface Props {
  ref?: React.RefObject<HTMLDivElement>;
  action: string;
  icon: IconType;
  tooltipClassName?: string;
  iconProps?: IconBaseProps;
  iconColor?: string;
  iconSize?: string;
  onClick: () => void;
  classes?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  hoverColor: 'red' | 'green' | 'yellow' | 'blue' | 'grey';
  disabled?: boolean;
  disabledMessage?: string;
  dataCy?: string;
  forcePlacement?: boolean;
  noWrap?: boolean;
}

const colorThemes = {
  red: {
    tooltip: '!bg-[#ce452a] text-white',
    iconHover: 'hover:!text-[#ce452a]',
    icon: 'text-[#ce452a]',
  },
  green: {
    tooltip: '!bg-[#52b73b] text-white',
    iconHover: 'hover:!text-[#52b73b]',
    icon: 'text-[#52b73b]',
  },
  yellow: {
    tooltip: '!bg-yellow-500 text-default',
    iconHover: 'hover:!text-yellow-500',
    icon: 'text-yellow-500',
  },
  blue: {
    tooltip: '!bg-blue-500 text-white',
    iconHover: 'hover:!text-blue-500',
    icon: 'text-blue-500',
  },
  grey: {
    tooltip: '!bg-[#808080] text-white',
    iconHover: 'hover:!text-[#808080]',
    icon: 'text-[#808080]',
  },
};

const Action = ({ iconProps, tooltipClassName, ref, forcePlacement, noWrap, ...props }: Props) => (
  <div className={cn(props.classes)} ref={ref}>
    <Tooltip
      content={props.disabled ? props.disabledMessage : props.action}
      placement="top"
      withArrow
      className={cn(colorThemes[props.hoverColor]?.tooltip, tooltipClassName)}
      onMouseEnter={() => {
        if (props.onMouseEnter && !props.disabled) {
          props.onMouseEnter();
        }
      }}
      onMouseLeave={() => {
        if (props.onMouseLeave && !props.disabled) {
          props.onMouseLeave();
        }
      }}
      forcePlacement={forcePlacement}
      noWrap={noWrap}
      renderOpener={tooltipProps => (
        <div {...tooltipProps} className="w-fit">
          <props.icon
            className={cn(
              colorThemes[props.hoverColor]?.iconHover,
              props.disabled ? '!text-[#808080]' : '',
              props.iconColor
                ? colorThemes[props.iconColor]?.icon || `!text-[${props.iconColor}]`
                : '!text-blue',
            )}
            size={props.iconSize}
            color={props.iconColor}
            onClick={event => {
              if (!props.disabled) {
                props.onClick();
                event.stopPropagation();
              }
            }}
            data-cy={props.dataCy}
            {...iconProps}
          />
        </div>
      )}
    />
  </div>
);

export default Action;
